<template>
  <div id="newsList" class="wi100 hi100">
    <dv-scroll-board style="padding: 0px" :config="dataConfig"/>
  </div>
</template>

<script>
export default {
  name: 'news-list',
  props: {
    newsList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      dataConfig: {
        index: false,
        headerHeight: 35,
        columnWidth: [450],
        align: ['center','center','center'],
        rowNum: 8,
        // 头部背景色
        // headerBGC: '#d35400',
        oddRowBGC: '#0e5ba7',
        evenRowBGC: '#0b97ff'
      },
      // resultList: [],
      responseList: []
    }
  },
  created () {
    // this.initNews(this.enterpriseId)
  },
  mounted () {
    let list = []
    this.newsList.forEach( item => {
      let itemList = [`<a href="${item.url}" target="_blank" type="info" style="color:#FFFFFF">${item.title}</a>`, item.source,item.publishTime]
      list.push(itemList)
    })
    let dataConfig = {...this.dataConfig}
    dataConfig.data = [...list]
    this.dataConfig = dataConfig
  },
  watch: {
    newsList: {
      handler: function (dataList) {
        let list = []
        dataList.forEach( item => {
          let itemList = [`<a href="${item.url}" target="_blank" type="info" style="color:#FFFFFF">${item.title}</a>`, item.source,item.publishTime]
          list.push(itemList)
        })
        let dataConfig = {...this.dataConfig}
        dataConfig.data = [...list]
        this.dataConfig = dataConfig
        // this.resultList = list
      }
    }
  },
  methods: {
    initNews (val) {
      this.$http({
        url: this.$http.adornUrl(this.$api.QCC.NEWS.LIST),
        method: 'GET',
        params: this.$http.adornParams({
          enterpriseId: val
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let resultList = [...data['result']['news']]
          let list = []
          resultList.forEach(item => {
            let itemList = [item.title, item.source,item.publishTime]
            list.push(itemList)
          })
          let dataConfig = {...this.dataConfig}
          dataConfig.data = [...list]
          this.dataConfig = dataConfig
        }
      })
    }
  }
}
</script>

<style scoped>
 /deep/ .header {
    padding: 0px;
  }
</style>
